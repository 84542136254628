.BottomTabBarBox{
    width: 100%;
    height: 167px;
    background-color: #282828;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .content{
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .leftBox{
            .listBox{
                display: flex;
                .tabBarText{
                    font-size: 16px;
                    margin: 0;
                    color: #c8c8c8;
                    font-weight: bold;
                    margin-right: 40px;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
            .addressBox{
                margin-top: 22px;
                .addressText{
                    text-align: left;
                    font-size: 13px;
                    margin: 0;
                    color: #888;
                    line-height: 28px;
                }
                .beiAnHao{
                    cursor: pointer;
                    text-align: left;
                    font-size: 13px;
                    margin: 0;
                    color: #888;
                    line-height: 28px;
                    text-decoration: underline;
                }
            }
        }
        .rightBox{
            .iconImg{
                width: 150px;
                height: 56px;
            }
        }
    }
}