.productBox {
  margin-top: 100px;
  height: 980px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #f4f5f8;

  .contentBox {
    overflow: hidden;
    width: 1200px;
    position: relative;

    .dotsBoxBtn {
      position: absolute;
      bottom: 100px;
      right: 0px;
      display: flex;
      flex-direction: row;
      z-index: 9999;

      .leftIconBox {
        width: 60px;
        height: 60px;
        margin-right: 18px;
        cursor: pointer;

        .IconImg {
          width: 60px;
          height: 60px;
        }
      }
    }

    .tabBar {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
      height: 90px;

      .imgIcon {
        width: 60px;
        height: 60px;
        transition: all .3s;
        cursor: pointer;
      }

      .imgIconHover {
        width: 90px;
        height: 90px;
        transition: all .3s;
        cursor: pointer;
      }
    }

    .title {
      margin-top: 60px;
      font-size: 36px;
      color: #666666;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .itemBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 50px;

      .itemContent {
        display: flex;
        position: relative;
        margin-top: 80px;

        .phoneImg {
          position: absolute;
          left: 101px;
          top: 0px;
          width: 270px;
          height: 560px;
          box-shadow: 0px 0px 30px -20px #0338AF;
        }

        .backImg {
          width: 246px;
          height: 530px;
          margin-top: 12px;
          margin-left: 113px;
        }

        .itemLeft {
          margin-left: 138px;

          .itemTittle {
            text-align: left;
            font-size: 32px;
            color: #666;
            margin-top: 138px;
            margin-bottom: 0;
          }

          .line {
            width: 50px;
            height: 4px;
            background-color: #2465FA;
            margin-top: 30px;
            margin-bottom: 0;
          }

          .descText {
            display: block;
            margin-top: 58px;
            text-align: left;
            width: 590px;
            font-size: 16px;
            color: #666;
            line-height: 36px;
          }
        }
      }
    }
  }
}

.containerRow {
  display: flex;
  flex-direction: row;
}

.downloadButton {
  margin-left: 20px;
  margin-top: 15px;
  font-size: 20px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #2465FA;
  background-color: #ccc
}
