.recruittBox{
    width: 100%;
    padding-top: 179px;
    height: 1080px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pageTitle{
        color: #666;
        font-size: 36px;
        // margin-top: 79px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: bold;
    }
    .contentBox{
        cursor: pointer;
        width: 1200px;
        margin-top: 59px;
        box-shadow: 0px 0px 30px -20px #0338AF;
        .ant-carousel .slick-dots li.slick-active button{
            background-color: #2465FA;
        }
        .ant-carousel .slick-dots li {
            color: #fff;
            background: #DADFE3;
        }
        .ant-carousel .slick-dots-bottom{
            bottom: -40px;
        }
        .itemBox{
            width: 1200px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .itemLeftBox{
                padding-left: 50px;
                width: 700px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .itemText{
                    margin-top: 50px;
                    font-size: 24px;
                    color: #333;
                    text-align: left;
                    margin-bottom: 0;
                    height: 24px;
                }
                .descTitle{
                    margin-top: 29px;
                    color: #333;
                    margin-bottom: 0;
                    font-weight: bold;
                    margin-bottom: 18px;
                    height: 14px;
                }
                .needTitle{
                    margin-top: 20px;
                    color: #333;
                    margin-bottom: 0;
                    font-weight: bold;
                    margin-bottom: 18px;
                    height: 14px;
                }
                .descText{
                    line-height: 26px;
                    margin-bottom: 0;;
                    font-size: 12px;
                    color: #333;
                    text-align: left;
                    font-weight: bold;
                }
            }
            .backBox{
                .backImg{
                    width: 500px;
                    height: 500px;
                }
            }
        }
    }
    .btnBox{
        width: 280px;
        background: #2465FA;
        border-radius: 4px;
        margin-top: 82px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .subText{
            font-size: 16px;
            color: #fff;
            margin: 0;
            height: 48px;
            line-height: 48px;
        }
    }
    .backBigImg{
        width: 100%;
        height: 169px;
        margin-top: 8px;
    }
    .ant-carousel .slick-dots{
        margin: 0;
    }
    .slick-dots li button:before{
        display: none;
    }
}