.callWeBox{
    padding-top: 100px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    .backImg{
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 913px;
        z-index: -1;
    }
    .contentBigBox{
        flex:1;
    }
    .contentBox{
        margin-top: 195px;
        margin-left: 49%;
        width: 570px;
        height: 324px;
        padding-top: 50px;
        padding-left: 50px;
        box-sizing: border-box;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .titleText{
            color: #666;
            font-size: 32px;
            font-weight: bold;
            height: 31px;
            line-height: 31px;
            margin: 0;
        }
        .line{
            background-color: #2465FA;
            width: 50px;
            height: 4px;
            margin-top: 30px;
            margin-bottom: 11px;
        }
        .itemText{
            height: 18px;
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 29px;
            color: #666;
            font-weight: bold;
        }
    }
}