.indexBox{
    width: 100%;
    height: 100%;
    touch-action: none;
    overflow-x: hidden;
}
.text{
    color: blue;
}
.contentStyle{
    height: 160px;
    color:#fff;
    line-height: 160px;
    text-align: center;
    background-color:#364d79;
}
// .bannerImg{
//     width: 100%;
//     height: 100%;
// }
.tabBox{
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: center;
}
.qwe{
    width: 1080px;
    height: 1920px;
    overflow: hidden;
    position: relative;
}