.aboutBigBox{
    display: flex;
    justify-content: center;
    height: 100%;
    .letBackBox{
        width: 713px;
        height: 655px;
        position: absolute;
        top: 100px;
        left: 0px;
        z-index: -1;
        .letBack{
            width: 713px;
            height: 655px;
        }
    }

    .aboutPage{
        padding-top: 100px;
        position: relative;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        .leftBox{
            position: relative;
            .titleBox{
                margin-top: 254px;
                display: flex;
                flex-direction: row;
                height: 67px;
                align-items: center;
                .titleItem{
                    font-size: 32px;
                    color: #2465FA;
                    margin-right: 29px;
                    line-height: 36px;
                    transition: all .3s;
                    cursor: pointer;
                }
                .titleItemNo{
                    font-size: 23px;
                    color: #343434;
                    margin-right: 29px;
                    line-height: 36px;
                    transition: all .3s;
                    cursor: pointer;
                }
            }
            .descBox{
                margin-top: 90px;
                width: 600px;
                .descItem{
                    font-size: 16px;
                    color: #343433;
                    line-height: 36px;
                    text-align: left;
                    cursor: pointer;
                }
            }
            .dotsBoxBtn{
                position: absolute;
                top: 723px;
                left: 0px;
                display: flex;
                flex-direction: row;
                .leftIconBox{
                    width: 60px;
                    height: 60px;
                    margin-right: 18px;
                    cursor: pointer;
                    .IconImg{
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
        .backImgBox{
            width: 460px;
            height: 696px;
            margin-top: 90px;
            box-shadow: 0px 100px 200px -180px #0338AF;
            .backImg{
                width: 460px;
                height: 696px;
            }
        }
    }
}