.bannerBox{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .dotsBox{
        position: absolute;
        right: 66px;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999999999;
        display: flex;
        flex-direction: column;
    }
    .slick-vertical .slick-slide{
        border: 0;
        // height: 639px;
    }
    .ant-carousel .slick-dots{
        margin: 0;
    }
    .slick-dots li button:before{
        display: none;
    }
}
.text{
    color:#fff
}
.dotsIcon{
    width: 42px;
    height: 42px;
    cursor: pointer;
}