.tabBarBox{
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    .logoImg{
        width: 125px;
        height: 50px;
    }
    .tabListBox{
        display: flex;
        flex-direction: row;
        .tabList{
            position: relative;
            display: flex;
            margin-right: 59px;
            .tabText{
                color: #fff;
                font-size: 14px;
                cursor: pointer;
            }
            .tabTextBlue{
                color: blue;
                font-size: 14px;
                cursor: pointer;
            }
            .tabTextBlack{
                color: #343434;
                font-size: 14px;
                cursor: pointer;
            }
            .dotsIcon{
                position: absolute;
                bottom: -9px;
                width: 10px;
                height: 6px;
                left: 50%;
                margin-left: -6px;
            }
        }
    }
}