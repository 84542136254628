.homePage{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .itemBox{
        width: 100%;
        position: relative;
        .textBox{
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            user-select:none;
            cursor: pointer;
            .textBoxSm{
                display: flex;
                flex-direction: column;
                .titleText{
                    font-size: 54px;
                    font-weight: bold;
                    color: #fff;
                    user-select:none;
                }
                .descText{
                    font-size: 33px;
                    font-weight: bold;
                    color: #fff;
                    user-select:none;
                }
            }
        }
    }
    .bannerImg{
        width: 100%;
        // height: 100%;
    }
    .ant-carousel .slick-dots{
        margin: 0;
    }
    .slick-dots li button:before{
        display: none;
    }
}